import jQuery from "jquery";

(function ($) {
  //スムーススクロール
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();

    const speed = 500;
    const href = $(this).attr('href');
    const target = $(href === '#' || href === '' ? 'html' : href);
    if (!target.length) return;

    $('#site-main').animate({
        scrollLeft: target.offset().left - $('#section-fv').offset().left,
    }, speed, 'swing');

    return false;
  });
}) (jQuery);

(function ($) {
  const $siteMain = $('#site-main');

  const mainWidth = $siteMain.get(0).scrollWidth;
  const footerWidth = $('#section-footer').width();
  const contentsWidth = mainWidth - footerWidth;
  const $animObj = $('#anim-obj');

  $siteMain.on('scroll', function () {
      const scroll = $siteMain.scrollLeft();
      const position = (scroll / contentsWidth) * 100 + 5 + '%';

      $animObj.css('left', position);
  });

}) (jQuery);

(function ($) {
  const $siteMain = $('#site-main');

  function changeScrollVerticalToHorizontal(e) {
    const siteMain = $siteMain.get(0);
    const { deltaX, deltaY } = e.originalEvent;
    if (Math.abs(deltaY) < Math.abs(deltaX)) return;

    const maxScrollLeft = siteMain.scrollWidth - siteMain.clientWidth;

    if (
        (siteMain.scrollLeft <= 0 && deltaY < 0) ||
        (siteMain.scrollLeft >= maxScrollLeft && deltaY > 0)
    ) {
      return;
    }

    e.preventDefault();
    siteMain.scrollLeft += deltaY;
  }

  let timeOut;
  $('.scroll-box').on("mousewheel", function (e) {
    $siteMain.off('mousewheel');

    clearTimeout(timeOut);

    timeOut = setTimeout(function(){
      $siteMain.on('mousewheel', changeScrollVerticalToHorizontal);
    }, 100);
  });

  $siteMain.on('mousewheel', changeScrollVerticalToHorizontal)
})(jQuery);
